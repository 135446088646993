import { gsap } from 'gsap'
import DomElementAnimation from '../DomElementAnimation'

/**
 * A custom element class for accessing window events and associated callbacks for animations
 * @extends DomElementAnimation
 * @property {Function} animate the animation this class will run
 * @property {Function} setDefault set defaults for animation or element
 * @property {Function} onViewportEnter a callback function for intersection observer enter
 * @property {Function} onViewportExit a callback function for intersection observer exit
 */
class LogoFade extends DomElementAnimation {
	/**
		* Runs the animation
		* @param {Function} done animation complete callback, passed into run()
		*/

	/**
		 * Will fire when element binds to the DOM, use this to
		 * set up default states if needed
		 */
	setDefault() {
		this.logo = document.querySelector('.main-logo')

		/** --- Settings --- */
		this.element.resetOnExit = true // Run the animation in both directions of scroll
	}

	/**
		* Intersection Observer callback, only needs to fire this.run()
		* this.run() can contain a callback function for done()
		*/
	onViewportEnter() {

		gsap.fromTo(this.logo, {
			opacity: 1
		}, {
			opacity: 0,
			duration: 0.4,
			ease: 'linear'
		})

		gsap.fromTo(this.logo, {
			y: 0
		}, {
			duration: 0.35,
			y: 30,
			ease: 'expo.out'
		})
		/** --- Fire the animation with a callback function for done() --- */
		this.run(() => {

		})
	}

	/**
		* Intersection Observer callback for exit
		* can be used to reset animation (timeline?)
	  */
	onViewportExit() {
		gsap.fromTo(this.logo, {
			y: 30
		}, {
			duration: 0.35,
			y: 0,
			ease: 'Power4.inOut'
		})
		gsap.fromTo(this.logo, {
			opacity: 0
		}, {
			duration: 0.5,
			opacity: 1,
			ease: 'linear'
		})

		// if (this.element.resetOnExit) this.reset()
	}
}

export default LogoFade
