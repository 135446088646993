import DomElementAnimation from '../DomElementAnimation'
import { gsap } from 'gsap'

/**
 * A custom element class for accessing window events and associated callbacks for animations
 * @extends DomElementAnimation
 * @property {Function} animate the animation this class will run
 * @property {Function} onViewportExit a callback function for intersection observer exit
 */
class Global extends DomElementAnimation {
	/**
		 * Runs the animation
		 * @param {function} done animation complete callback, passed into run()
		 */
	animate({ done }) {
		gsap.to(this.element, { duration: 0.4, autoAlpha: 0, onComplete: done })
	}

	/**
		 * Intersection Observer callback, only needs to fire this.run()
		 * this.run() can contain a callback function for done()
		 */
	onViewportEnter() {
		this.run(() => {

		})
	}
}

export default Global
