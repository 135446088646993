import '../js/extensions/Shopify'

import isMobile from 'ismobilejs'

import AnimationInitializer from '../js/animation/Initializer'
import PageTransition from '../js/animation/PageTransition'
import Navigation from '../js/components/Navigation.js'
import newsletterSignupInit from '../js/components/NewsletterSignup.js'
import '../js/helpers/scrollListener'

import * as h from '../js/helpers'

if (document.body.classList.contains('page-collection')) import('../js/pages/collection.js')

const helpers = { ...h }

self.Decimal = self.Decimal || {}
self.Decimal.helpers = helpers
self.Decimal.components = self.Decimal.components || {}
self.Decimal.isMobile = isMobile().phone
self.Decimal.windowWidth = helpers.getViewport().width

self.Decimal.scroll = self.Decimal.scroll || {}
self.Decimal.scroll.current = window.scrollY
self.Decimal.scroll.last = null

for (const [key, value] of Object.entries(helpers)) {
  self.Decimal.helpers[key] = value
}

/** --- Bind custom events --- */
self.Decimal.dispatches = {
  animate: new Event('animate'),
  history: new Event('history'),
  resized: new Event('resized'),
  mutate: new Event('mutate'),
  enter: new Event('viewportEnter'),
  exit: new Event('viewportExit')
}
// Force animation for bfcache
window.addEventListener('pageshow', (event) => {
  if (event.persisted) {
    self.Decimal.pageTransition = new PageTransition()
  }
})

self.Decimal.animation = new AnimationInitializer({
  threshold: 0.5
})

self.Decimal.pageTransition = new PageTransition()

/*
**
** TODO:PAGES init
**
*/

/*
**
** GLOBAL COMPONENTS init
**
*/
const navigation = new Navigation()
self.Decimal.components.Navigation = navigation

// Initialize animations
self.Decimal.animation.init()

newsletterSignupInit.init()

document.addEventListener('DOMContentLoaded', () => {
  self.Decimal.animation = self.Decimal.animation || new AnimationInitializer({
    threshold: 0.5
  })

  self.Decimal.pageTransition = self.Decimal.pageTransition || new PageTransition()

  /*
  **
  ** TODO:PAGES init
  **
  */

  /*
  **
  ** GLOBAL COMPONENTS init
  **
  */
  const navigation = self.Decimal.components.Navigation || new Navigation()
  self.Decimal.components.Navigation = navigation

  // Initialize animations
  self.Decimal.animation.init()

  newsletterSignupInit.init()
})
