import gsap from 'gsap'
import isMobile from 'ismobilejs'

class PageTransition {
    constructor() {
        this.main = document.getElementById('main')
        this.overlay = document.querySelector('.main-overlay')
        this.tl = gsap.timeline({ paused: true })

        this.init()
    }

    init() {
        if (location.pathname.includes('tagged')) {
            return
        }
        gsap.fromTo(this.main, {
            y: 10
        }, {
            y: 0,
            clearProps: true,
            duration: 0.6

        })
        gsap.fromTo(this.overlay, {
            autoAlpha: 1
        }, {
            autoAlpha: 0,
            duration: 0.8
            // duration: 5
        })

        const navigateWithTransition = (url) => {
            // Apply fade out effect

            gsap.fromTo(this.main, {
                y: 0
            }, {
                y: -10,
                clearProps: true,
                duration: 0.6

            })

            gsap.fromTo(this.overlay, {
                autoAlpha: 0
            }, {
                autoAlpha: 1,
                duration: 0.8,
                onComplete: () => {
                    window.location.href = url
                }

            })
        }

        // Attach event listeners to internal links
        const internalLinks = [...document.querySelectorAll('a[href^="/"]:not([target="_blank"]):not([data-no-transition])')]
            .filter((link) => !link.href.includes('tagged'))
            .filter((link) => isMobile().any && !link.classList.contains('dropdown-trigger'))
            .filter(link => !link.classList.contains('load-more')
            )

        internalLinks.forEach((link) => {
            link.addEventListener('click', (event) => {
                event.preventDefault() // Prevent default link behavior
                // navigateWithTransition.bind(this, link.getAttribute('href'))
                navigateWithTransition(link.getAttribute('href')) // Trigger transition
            })
        })
    }
}

export default PageTransition
